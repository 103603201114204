export const DomUtils = {
  remToPX: (rem: number): number =>
    rem * parseFloat(getComputedStyle(document.documentElement).fontSize),

  pxToRem: (px: number): number =>
    px / parseFloat(getComputedStyle(document.documentElement).fontSize),

  calculateScroll: (e?: HTMLElement|null): number => {
    if (e) {
      const scrollTop = DomUtils.calculateScroll(e.parentElement);
      return (e.scrollTop || 0) + scrollTop;
    } else {
      return 0;
    }
  },

  scaleWindow: (scale?: number): void => {
    if (scale) {
      const root = document.querySelector(':root') as HTMLElement;
      const size = parseInt(window.getComputedStyle(root).fontSize);
      if (!isNaN(size)) {
        root.style.fontSize = Math.round(size * scale) + 'px';
      }
    }
  },

  getQuery: (property: string): string | null => {
    return new URLSearchParams(window.location.search).get(property);
  },

  isIFrame: () => !!window.parent && (window.parent !== window),

  isSensor: () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0),

  isMobile: () => innerWidth <= 600
};
